import * as yup from "yup";
export const validationSchema = yup.object().shape({
  companyName: yup.string().required("Company Name is Required").max(50),
  userLimit: yup.number().required("User limit is Required"),
  onBoardingPassword: yup.string().required("Onboarding password is required"),
  office: yup.object({
    name: yup.string().required("Office name is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string(),
    zip: yup.string().required("Zip/Postal Code is required"),
    phone: yup.number().required(),
    // .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, "Must contain 10 digits")
    // .nullable(),
  }),
  user: yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required"),
    phone: yup.number().required("Phone is required"),
  }),
});

export const editCompanyValidationSchema = yup.object().shape({
  companyName: yup.string().required("Company Name is Required"),
  userLimit: yup.number().required("User limit is Required"),
  onBoardingPassword: yup.string().required("Onboarding password is required"),
});
