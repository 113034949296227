import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import Modal from "../../components/modal/Modal";
import AddAgentForm from "../../components/directory/addAgentForm";
import CompanyDirectoryEditView from "../../components/companies/CompanyDirectoryEditView";
import CompanyDirectoryDeleteView from "../../components/companies/CompanyDirectoryDeleteView";
import Breadcrumb from "../../components/Breadcrumb";
import { GET_AGENTS } from "../../graphql/directoryQueries";
import { useQuery } from "@apollo/client";

const CompanyView = ({
  companyId,
  handleBackClick,
  companyName,
  details,
  directoryName,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [formType, setFormType] = useState("add");
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const [getDetails, setGetDetails] = useState(details);

  const {
    data: agentsData,
    loading: agentLoading,
    refetch: refetchAgentData,
  } = useQuery(GET_AGENTS, {
    variables: { companyId },
    fetchPolicy: "no-cache",
    onCompleted(result) {
      // console.log(result);
      // console.log("result");
      // setGetDetails(result?.getDirectoryList?.[0]?.details);
    },
  });

  useEffect(() => {
    setGetDetails(agentsData?.getDirectoryList?.[0]?.details);
  }, [agentsData]);

  const handleEditModalRender = (agentToEdit) => {
    setFormType("edit");
    setSelectedAgent(agentToEdit);
    setAgentId(agentToEdit._id);
    setShowEditModal(true);
  };

  const handleDeleteModalRender = (id) => {
    setAgentId(id);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="w-full pt-10 flex flex-col ">
        <div
          onClick={handleBackClick}
          className="w-full cursor-pointer flex items-center flex-row gap-4"
        >
          <IoIosArrowRoundBack color="#212636" size={30} />
          <p className="font-normal text-[20px] text-[#212636]">Back</p>
        </div>
        <Breadcrumb
          pageName="Directory -"
          span={directoryName + " -"}
          company={companyName}
        />
        <div className="flex flex-row flex-wrap gap-x-10 border border-gray-200 p-4 rounded-2xl shadow-sm">
          <div className="h-20 flex items-center justify-end w-full px-10">
            <button
              onClick={() => setShowModal(true)}
              className="bg-[#B4C540] h-[36px] px-5 text-center rounded-xl text-[#FFFFFF]"
            >
              + Add Contacts
            </button>
          </div>
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-4 px-4 font-medium text-black">
                  Contact Name
                </th>
                <th className="py-4 px-4 font-medium text-black">Address</th>
                <th className="py-4 px-4 font-medium text-black">
                  Phone Number
                </th>
                <th className="py-4 px-4 font-medium text-black">Website</th>
                <th className="py-4 px-4 font-medium text-black">Actions</th>
              </tr>
            </thead>
            <tbody>
              {getDetails?.length > 0 ? (
                getDetails?.map((user, index) => (
                  <tr key={index} className="border-b border-[#eee]">
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.name || "No Name"}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.address || "No Address"}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.phone || "No Phone Number"}
                    </td>
                    <td className="py-4 px-4 font-medium text-black">
                      {user?.url || "No Website"}
                    </td>
                    <td className="col-span-1 border-b border-[#eee] py-5 px-4">
                      <div className="flex items-center justify-start">
                        <button
                          className="hover:text-primary"
                          onClick={() => handleEditModalRender(user)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                            <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                          </svg>
                        </button>
                        <button
                          className="hover:text-primary"
                          onClick={() => handleDeleteModalRender(user._id)}
                        >
                          <svg
                            className="fill-current"
                            width="20"
                            height="20"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                              fill=""
                            />
                            <path
                              d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                              fill=""
                            />
                            <path
                              d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                              fill=""
                            />
                            <path
                              d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                              fill=""
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-4 px-4 text-center">
                    No contacts added yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Modal
          title={"Add Contacts"}
          showModal={showModal}
          setShowModal={(value) => {
            setShowModal(value);
          }}
        >
          <AddAgentForm
            companyId={companyId}
            handleClose={handleClose}
            onDirectoryListAdded={() => {}}
            formType={formType}
          />
        </Modal>
        <Modal
          title={"Edit Contacts"}
          showModal={showEditModal}
          setShowModal={(value) => {
            setShowEditModal(value);
          }}
        >
          <CompanyDirectoryEditView
            companyId={companyId}
            agentId={agentId}
            initialValues={selectedAgent}
            refetchData={() => {}}
            handleClose={() => {
              setShowEditModal(false);
              refetchAgentData({ companyId });
            }}
          />
        </Modal>

        <Modal
          title={"Delete Contact"}
          showModal={showDeleteModal}
          setShowModal={(value) => {
            setShowDeleteModal(value);
          }}
        >
          <CompanyDirectoryDeleteView
            setShowDeleteModal={setShowDeleteModal}
            agentId={agentId}
            refetchData={() => {}}
            companyId={companyId}
            handleClose={() => {
              setShowDeleteModal(false);
            }}
          />
        </Modal>
      </div>
    </>
  );
};
export default CompanyView;
