import { useMutation } from "@apollo/client";
import { ADD_OFFICE, UPDATE_OFFICE } from "../../graphql/officeQueries";
import { useToast } from "../Toast/toastHook";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { validationSchema } from "./utils";
import Input from "../Input";
import { Button, Select } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const USA_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const OfficeForm = ({
  companyId,
  office,
  formType,
  handleClose,
  allOffices,
  refetchOffices,
}) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [initialValues, setInitialValues] = useState({
    _id: "",
    name: "",
    company: companyId,
    address: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    isDefault: false,
    listing_extension_period: 3,
  });
  const [subscribedOffices, setSubscribedOffices] = useState(
    office?.subscribedOffices ?? []
  );

  const toast = useToast(3000);
  const formikRef = useRef();
  const [addOffice, { data, loading: addLoading, error }] = useMutation(
    ADD_OFFICE,
    {
      // refetchQueries: [
      //   {
      //     query: GET_COMPANY_OFFICES,
      //     variables: {
      //       companyId,
      //     },
      //   },
      // ],
      onCompleted(result) {
        toast("success", `Successfully saved ${result.addOffice?.name}!`);
        formikRef.current?.resetForm();
        handleClose();
        refetchOffices({ companyId });
      },
      onError(e) {
        toast("error", `There was an issue saving the data, please try again`);
        console.error(e);
      },
    }
  );
  const [updateOffice, { loading: editLoading }] = useMutation(UPDATE_OFFICE, {
    // refetchQueries: [
    //   {
    //     query: GET_COMPANY_OFFICES,
    //     variables: {
    //       companyId,
    //     },
    //   },
    // ],
    onCompleted(result) {
      toast("success", `Successfully saved ${result.updateOffice?.name}!`);
      handleClose();
      refetchOffices({ companyId });
    },
    onError(e) {
      toast("error", `There was an issue saving the data, please try again`);
      console.error(e);
    },
  });
  const handleFormData = () => {
    if (formType === "edit") setInitialValues({ ...initialValues, ...office });
    else formikRef.current?.resetForm();
  };

  useEffect(() => {
    handleFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office]);

  const handleSave = (values) => {
    if (formType === "add")
      addOffice({
        variables: {
          officeInput: {
            ...values,
            listing_extension_period: values.listing_extension_period
              ? values.listing_extension_period
              : 3,
            subscribedOffices,
          },
        },
      });
    else {
      const {
        _id,
        name,
        address,
        city,
        state,
        zip,
        phone,
        isDefault,
        listing_extension_period,
      } = values;
      updateOffice({
        variables: {
          officeInput: {
            _id,
            name,
            address,
            city,
            state,
            zip,
            phone,
            isDefault,
            subscribedOffices,
            listing_extension_period: listing_extension_period
              ? parseInt(listing_extension_period)
              : 3,
          },
        },
      });
    }
  };

  const handleNotificationsChange = (value) => {
    setSubscribedOffices(value);
  };

  const getOptions = () => {
    return allOffices
      .map((o) => ({ label: o.name, value: o.id }))
      .filter((o) => o.value !== office?._id);
  };

  const selectAllOffices = () => {
    setSubscribedOffices(getOptions().map((o) => o.value));
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values, resetForm) => {
        handleSave(values, resetForm);
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6 md:flex-row">
            <div className="w-full md:w-1/2">
              <Input
                label={"Name"}
                name="name"
                error={errors.name}
                value={values["name"]}
                onChange={handleChange}
                type="text"
                placeholder={"Enter the office name"}
              />
            </div>

            <div className="w-full md:w-1/2">
              <Input
                label={"Address"}
                name="address"
                error={errors.address}
                value={values["address"]}
                onChange={handleChange}
                type="text"
                placeholder={"Enter the office address"}
              />
            </div>
          </div>

          <div className="mb-3 flex flex-col gap-6 md:flex-row">
            <div className="w-full md:w-1/2">
              <Input
                label={"City"}
                name="city"
                error={errors.city}
                value={values["city"]}
                onChange={handleChange}
                type="text"
                placeholder={"Enter the city"}
              />
            </div>

            <div className="w-full md:w-1/2">
              <Input
                label={"Zip/Postal Code"}
                name="zip"
                error={errors.zip}
                value={values["zip"]}
                onChange={handleChange}
                type="text"
                placeholder={"Enter the Zip or Postal Code"}
              />
            </div>
          </div>

          <div className="mb-3 flex flex-col gap-6 md:flex-row">
            <div className="w-full md:w-1/2">
              {/* <Input
                label={"State/Province"}
                name="state"
                value={values["state"]}
                onChange={handleChange}
                type="text"
                error={errors.state}
                placeholder={"Enter the state or province"}
              /> */}
              <label className="mb-2 block text-black ">State/Province</label>
              <Select
                className="select-style"
                // size="large"
                placeholder={"Enter the state or province"}
                onChange={(value) => setFieldValue("state", value)}
                value={values["state"] || null}
                options={USA_STATES.map((state) => ({
                  value: state,
                  label: state,
                }))}
                style={{
                  height: "48px",
                  marginTop: "2px",
                  width: "100%",
                }}
              />
              {errors.state && (
                <div className="text-xs text-red">{errors.state}</div>
              )}
            </div>

            <div className="w-full md:w-1/2">
              <Input
                label={"Phone Number"}
                name="phone"
                value={values["phone"]}
                onChange={handleChange}
                type="text"
                error={errors.phone}
                placeholder={"Enter the phone number"}
              />
            </div>
          </div>

          <div className="mb-3 flex flex-col gap-6 md:flex-row">
            <div className="w-full md:w-1/2">
              <div className="w-full flex justify-between">
                <label>Notifications</label>
                <Button
                  size="small"
                  style={{ fontSize: 14, height: 22 }}
                  onClick={selectAllOffices}
                >
                  Select All
                </Button>
              </div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                  height: "max-content",
                }}
                placeholder="Select offices to receive notifications from"
                value={subscribedOffices}
                // defaultValue={}
                onChange={handleNotificationsChange}
                options={getOptions()}
                className="mt-2"
                showSearch={false}
                // bordered={false}
              />
            </div>

            {user.role?.name === "SUPER_ADMIN" ? <div className="w-full md:w-1/2">
              <Input
                label={"Days on Market (DOM)"}
                value={values["listing_extension_period"]}
                name="listing_extension_period"
                onChange={handleChange}
                type="number"
                error={errors.listing_extension_period}
                placeholder="Enter the number of days on market"
              />
            </div> : null}
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              disabled={addLoading || editLoading}
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting || addLoading || editLoading}
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              {addLoading || editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default OfficeForm;