import { gql } from "@apollo/client";

export const GET_DIRECTORIES = gql`
  query GetDirectory {
    getDirectory {
      _id
      title
      icon
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_AGENT = gql`
  mutation DeleteAgentInDirectoryList($companyId: String!, $agentId: String!) {
    deleteAgentInDirectoryList(company_id: $companyId, agent_id: $agentId)
  }
`;

export const ADD_AGENT = gql`
  mutation AddAgentInDirectoryList(
    $companyId: String!
    $agentInfo: [DETAILS_INPUT]
  ) {
    addAgentInDirectoryList(company_id: $companyId, agent_info: $agentInfo)
  }
`;

export const EDIT_AGENT = gql`
  mutation editAgentInDirectoryList(
    $companyId: String!
    $agentId: String!
    $agentInfo: [DETAILS_INPUT]
  ) {
    editAgentInDirectoryList(
      agent_id: $agentId
      company_id: $companyId
      agent_info: $agentInfo
    )
  }
`;

export const GET_AGENTS = gql`
  query GetDirectoryList($directoryId: String, $companyId: String) {
    getDirectoryList(directory_id: $directoryId, company_id: $companyId) {
      _id
      createdAt
      directory {
        _id
        createdAt
        icon
        title
        updatedAt
      }
      details {
        _id
        address
        email
        name
        phone
        position
        url
      }
      updatedAt
    }
  }
`;

export const GET_DIRECTORY_BY_COMPANY = gql`
  query GetDirectoryByCompany($companyId: String!) {
    getDirectoryByCompany(companyId: $companyId) {
      _id
      createdAt
      directory {
        _id
        createdAt
        icon
        title
        updatedAt
      }
      updatedAt
      details {
        address
        email
        name
        phone
        position
        url
      }
    }
  }
`;

export const ADD_COMPANY_IN_DIRECTORY = gql`
  mutation AddCompanyInDirectory($companyInput: DirectoryCompanyInput!) {
    addCompanyInDirectory(companyInput: $companyInput) {
      _id
      title
      icon
    }
  }
`;

export const GET_DIRECTORY_LIST = gql`
  query GetDirectoryList($companyId: String, $directoryId: String) {
    getDirectoryList(company_id: $companyId, directory_id: $directoryId) {
      _id
      company {
        _id
        activeListingsCount
        companyName
        website
        phone
        serviceAreas
        email
        address
        notes
        onBoardingPassword
      }
      details {
        _id
        address
        email
        name
        phone
        position
        url
      }
      updatedAt
    }
  }
`;

export const ADD_DIRECTORY = gql`
  mutation addDirectory($directoryInput: DirectoryInput) {
    addDirectory(directoryInput: $directoryInput) {
      _id
      title
      icon
    }
  }
`;

export const ADD_DIRECTORY_LIST = gql`
  mutation AddDirectoryList($directoryListInput: DIRECTORY_COMPANY_LIST) {
    addDirectoryList(directoryListInput: $directoryListInput) {
      response {
        _id
      }
    }
  }
`;

export const UPDATE_DIRECTORY = gql`
  mutation UpdateDirectory($directoryInput: DirectoryUpdateInput!) {
    updateDirectory(directoryInput: $directoryInput) {
      _id
      title
      icon
    }
  }
`;

export const EDIT_DIRECTORY_LIST = gql`
  mutation EditDirectoryList($directoryListInput: DIRECTORY_LIST_UPDATE_INPUT) {
    editDirectoryList(directoryListInput: $directoryListInput) {
      _id
      title
      details {
        name
        position
        address
        email
        phone
        url
        notes
        area
        password
      }
      directory {
        _id
      }
      company {
        _id
      }
    }
  }
`;

export const DELETE_DIRECTORY = gql`
  mutation DeleteDirectory(
    $deleteDirectoryDirectoryInput2: DirectoryDeleteInput
  ) {
    deleteDirectory(directoryInput: $deleteDirectoryDirectoryInput2) {
      _id
    }
  }
`;

export const DELETE_DIRECTORY_LIST = gql`
  mutation DeleteDirectoryList(
    $deleteDirectoryListDirectoryListInput2: DIRECTORY_LIST_DELETE_INPUT
  ) {
    deleteDirectoryList(
      directoryListInput: $deleteDirectoryListDirectoryListInput2
    ) {
      _id
    }
  }
`;
